<template>
  <div class="stats flex flex-col flex-1">
    <div class="flex flex-row items-end text-code gap-1">
      <div class="text-[64px]/[64px]">{{ widget.counter }}</div>
      <div v-if="widget.counterSubscript" class="text-[26px]/[44px]">{{ widget.counterSubscript }}</div>
    </div>
    <div class="px-1 font-extralight">{{ widget.description }}</div>
  </div>
</template>

<script setup lang="ts">
import type { VonageHomePageCommunityStatsWidget } from '@/types/storyblok';
import { computed, type PropType } from 'vue';

const props = defineProps({
  widget: { type: Object as PropType<VonageHomePageCommunityStatsWidget>, required: true },
});

const textColor = computed(() => props.widget?.textColor || 'var(--vvd-color-cta-900)');
</script>

<style lang="scss" scoped>
.stats {
  color: v-bind(textColor);
}
</style>
